.App {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
}

.product-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    transition: transform 0.2s;
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.product-card img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    margin-bottom: 10px;
}

.product-card h3 {
    font-size: 0.9rem;
    line-height: 1.2;
    margin: 8px 0;
    max-height: 2.4em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.product-card p {
    font-size: 0.8rem;
    margin: 4px 0;
}

.product-card .price {
    font-size: 16px;
    font-weight: bold;
    color: #B12704;
}

.product-card .rating {
    font-size: 14px;
    color: #666;
}

.loading {
    text-align: center;
    padding: 50px;
    font-size: 1.2rem;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #232f3e;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 20px auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    background-color: #fde2e2;
    color: #d32f2f;
    padding: 15px;
    border-radius: 4px;
    margin: 20px 0;
    text-align: center;
}

.search-results {
    text-align: center;
    color: #666;
    margin: 20px 0;
}

header {
    background: #232f3e;
    color: white;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
}

header h1 {
    margin: 0 0 20px 0;
    text-align: center;
}

.filters-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.filters-section {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.category-section {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .products-grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
        padding: 1rem;
    }
    
    .product-card {
        width: 100%;
    }
}
